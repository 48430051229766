@tailwind base;
@tailwind components;
@tailwind utilities;

/* Adobe Fonts is loaded via their script, so no @font-face declarations needed */

/* Legal Oracle Brand Variables */
:root {
  /* Font scaling variables and base styles */
  --base-font-size: 1rem;
  
  /* Brand Colors */
  --lo-orange: #F6845C; /* 1645C */
  --lo-gold: #F4C967;   /* 141C */
  --lo-pink: #F2B0C0;   /* 510C */
  --lo-navy: #293A4C;   /* 7546C */
  --lo-navy-dark: #192231; /* 539C */
  
  /* Typography - using Adobe Fonts for Proxima Nova Alt */
  --heading-font: 'proxima-nova-alt', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --body-sans-font: 'Segoe UI Variable', 'Segoe UI', -apple-system, BlinkMacSystemFont, sans-serif;
  --body-serif-font: 'Calisto MT', Georgia, serif;
}

@layer base {
  html {
    font-size: var(--base-font-size);
  }
  
  /* Make sure base elements have normal letter spacing */
  * {
    letter-spacing: normal;
  }
  
  /* Brand Typography Basics - only set font family by default */
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font);
    font-weight: 700;
  }
}

/* Typography utility classes */
@layer components {
  /* Use this class for elements that need the wide tracking from brand guide */
  .lo-heading-wide {
    font-family: var(--heading-font);
    letter-spacing: 0.3em; /* 300 tracking */
    font-weight: 700;
    text-transform: uppercase;
  }
  
  /* Use this for regular headings without the wide tracking */
  .lo-heading {
    font-family: var(--heading-font);
    font-weight: 700;
  }
  
  /* Body text styles */
  .lo-body-sans {
    font-family: var(--body-sans-font);
  }
  
  .lo-body-serif {
    font-family: var(--body-serif-font);
    letter-spacing: 0.01em; /* 10 tracking per brand guide */
  }
}

/* Base styles */
body {
  margin: 0;
  font-family: var(--body-sans-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Animations */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-down {
  animation: slideDown 0.5s ease-out;
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-progress {
  animation: progress 2s ease-in-out infinite;
}

/* Brand-specific animations */
@keyframes wave {
  0% { transform: translateY(0); }
  50% { transform: translateY(10px); }
  100% { transform: translateY(0); }
}

.lo-wave {
  animation: wave 15s ease-in-out infinite;
}

/* Dark mode color adjustments */
.dark {
  --lo-text-primary: var(--lo-gold);
  --lo-bg-primary: var(--lo-navy-dark);
}